import React, { useState, useEffect } from 'react';

const TempLogin = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const hardcodedUUID = '123e4567-e89b-12d3-a456-426614174000'; // Hardcoded UUID

  useEffect(() => {
    const authKey = localStorage.getItem('authKey');

    if (authKey && authKey === hardcodedUUID) {
      onLogin(true);
    }
  }, [onLogin]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Hardcoded credentials
    const hardcodedUsername = 'injsuradmin';
    const hardcodedPassword = 'Frailty123!!!';

    if (username === hardcodedUsername && password === hardcodedPassword) {
      localStorage.setItem('authKey', hardcodedUUID); // Store the hardcoded UUID
      onLogin(true);
    } else {
      setError('Incorrect username or password');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Injsur Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Username:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          {error && <p className="text-red-500 text-center mb-4">{error}</p>}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-200"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default TempLogin;
