// apiUtilities.js
import offensivePlayers from './combined_players.json';
import playerStatistics from './players_statistics_2023.json';  // Load the JSON file with the player stats
// import playerStatistics2024 from './players_statistics_2024.json';  // Load the JSON file with the player stats
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
// import playerStatistics2024 from './players_statistics_2024.json';  // Load the JSON file with the player stats
import { gunzip } from 'fflate';

import injuriesData from './injuries_data_9_19.json';
// Import aggregated_injuries.json
import aggregatedInjuries from './aggregated_injuries.json';
import missedGames from './missed_games.json'

// Cache objects for games and players
const gamesCache = {};
const playerDetailsCache = {};
const playerStatsCache = {};



let playerStatistics2024Cache = null;
let injuriesDataCache = null; // Cache for injuries data



const fetchInjuriesFromS3 = async () => {
    if (injuriesDataCache) {
        return injuriesDataCache; // Use cached data
    }

    const url = 'https://injsurplayerinjuries.s3.us-west-1.amazonaws.com/injuries_data_current_season.json.gz'; // Use the GZIP file URL

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept-Encoding': 'identity',  // Prevent GZIP compression on fetch
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Read the response as text, which is the compressed JSON
        const textData = await response.text(); // Read the response as text
        const jsonData = JSON.parse(textData); // Parse it as JSON

        injuriesDataCache = jsonData; // Cache the result
        return jsonData;
    } catch (error) {
        console.error('Error fetching injury data from S3:', error);
        return null;
    }
};

const fetchInjuryDetails = async (playerId) => {
    // Fetch the injuries data from S3
    const injuriesDataFromS3 = await fetchInjuriesFromS3();
    
    if (!injuriesDataFromS3) {
        console.error('Injuries data not available.');
        return null;
    }

    // Loop through the fetched S3 data, similar to how the local injuriesData was used
    for (const teamInjuries of Object.values(injuriesDataFromS3)) {
        const playerInjury = teamInjuries.find(injury => injury.player.id === playerId);
        if (playerInjury) {
            // Ensure that the injury description replaces &apos; with '
            playerInjury.description = playerInjury.description.replace(/&apos;/g, "'");
            return playerInjury;
        }
    }
    return null;
};

const fetchPlayerStatistics2024FromS3 = async () => {
    if (playerStatistics2024Cache) {
        return playerStatistics2024Cache; // Use cached data
    }

    const url = 'https://injsurplayerstats.s3.us-west-1.amazonaws.com/players_statistics_2024.json.gz'; // Use the GZIP file URL

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept-Encoding': 'identity',  // Prevent GZIP compression on fetch
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Since we can't decompress directly, read the response as text
        const textData = await response.text(); // Read the response as text
        const jsonData = JSON.parse(textData); // Parse it as JSON

        playerStatistics2024Cache = jsonData; // Cache the result
        return jsonData;
    } catch (error) {
        console.error('Error fetching player statistics from S3:', error);
        return null;
    }
};





// Helper function to convert stream to string
const streamToString = (stream) => {
    return new Promise((resolve, reject) => {
        const chunks = [];
        stream.on('data', (chunk) => chunks.push(chunk));
        stream.on('error', reject);
        stream.on('end', () => resolve(Buffer.concat(chunks).toString('utf-8')));
    });
};

// Add function to fetch pre-existing injuries
const fetchPreExistingInjuries = (playerId) => {
    const playerInjuries = aggregatedInjuries.find(injury => injury.id === playerId);
    return playerInjuries || null;
};


const getOffensivePlayerIds = () => {
    return Object.values(offensivePlayers).flatMap(position => position.map(player => player.id));
};

export const fetchGamesForTeam = async (teamId) => {
    if (gamesCache[teamId]) {
        // console.log('Using cached games for team:', teamId);
        return gamesCache[teamId];
    }

    const season = 2024;
    const url = `https://api-american-football.p.rapidapi.com/games?team=${teamId}&season=${season}`;
    const options = {
        method: 'GET',
        headers: {
            'X-RapidAPI-Key': '0be998d966mshcc7288b1c531576p19e5b0jsn08fc430b251e',
            'X-RapidAPI-Host': 'api-american-football.p.rapidapi.com'
        }
    };

    try {
        const response = await fetch(url, options);
        const data = await response.json();
        const games = data.response ? data.response : [];

        // Get today's date
        const today = new Date();

        // Filter games that occurred before or on today's date
        const filteredGames = games.filter(game => {
            const gameDate = new Date(game.game.date.date); // Game date format: "2024-08-10"
            return gameDate <= today;
        });

        // console.log('Filtered games up to today:', filteredGames); // Debug log

        // Cache the result
        gamesCache[teamId] = filteredGames;

        return filteredGames;
    } catch (error) {
        console.error('Error fetching games for team:', error);
        return [];
    }
};




const fetchPlayerStatsFromJson = (playerId, season = 2023) => {
    if (season === 2023) {
        return processPlayerStatsData(playerStatistics, playerId); // Process local 2023 stats
    } 

    return fetchPlayerStatistics2024FromS3().then((playerStatsData2024) => {
        if (!playerStatsData2024) {
            console.error('No player statistics data found for the 2024 season.');
            return {}; // Handle unavailable data
        }
        return processPlayerStatsData(playerStatsData2024, playerId);
    }).catch(error => {
        console.error('Error fetching 2024 stats from S3:', error);
        return {};
    });
};
// Helper function to process player stats data
const processPlayerStatsData = (playerStatsData, playerId) => {
    if (!Array.isArray(playerStatsData)) {
        console.error('Player statistics data is not in expected array format:', playerStatsData);
        return {};
    }

    const flatPlayerStats = playerStatsData.flat();

    const playerStats = flatPlayerStats.find(stat => stat.player.id === playerId);
    if (!playerStats) {
        // console.warn(`Player stats not found for player ID: ${playerId}`);
        return {}; 
    }

    return aggregatePlayerStats(playerStats);
};

// Helper function to aggregate player stats
const aggregatePlayerStats = (playerStats) => {
    const aggregatedStats = {};

    playerStats.teams.forEach(team => {
        team.groups.forEach(group => {
            if (group.name === "Passing") {
                group.statistics.forEach(stat => {
                    if (stat.name === "passing attempts") aggregatedStats.pass_attempts = (aggregatedStats.pass_attempts || 0) + parseInt(stat.value);
                    if (stat.name === "completions") aggregatedStats.pass_completions = (aggregatedStats.pass_completions || 0) + parseInt(stat.value);
                    if (stat.name === "yards") aggregatedStats.pass_yards = (aggregatedStats.pass_yards || 0) + parseInt(stat.value.replace(/,/g, '')); // Passing Yards
                    if (stat.name === "passing touchdowns") aggregatedStats.pass_touchdowns = (aggregatedStats.pass_touchdowns || 0) + parseInt(stat.value);
                    if (stat.name === "interceptions") aggregatedStats.interceptions = (aggregatedStats.interceptions || 0) + parseInt(stat.value);
                    if (stat.name === "sacks") aggregatedStats.sacks = (aggregatedStats.sacks || 0) + parseInt(stat.value); // Add this line
                });
            }

            if (group.name === "Rushing") {
                group.statistics.forEach(stat => {
                    if (stat.name === "rushing attempts") aggregatedStats.rush_attempts = (aggregatedStats.rush_attempts || 0) + parseInt(stat.value);
                    if (stat.name === "yards") aggregatedStats.rush_yards = (aggregatedStats.rush_yards || 0) + parseInt(stat.value.replace(/,/g, '')); // Rushing Yards
                    if (stat.name === "rushing touchdowns") aggregatedStats.rush_touchdowns = (aggregatedStats.rush_touchdowns || 0) + parseInt(stat.value);
                    if (stat.name === "fumbles lost") aggregatedStats.fumbles_lost = (aggregatedStats.fumbles_lost || 0) + parseInt(stat.value);
                });
            }

            if (group.name === "Receiving") {
                group.statistics.forEach(stat => {
                    if (stat.name === "receptions") aggregatedStats.receptions = (aggregatedStats.receptions || 0) + parseInt(stat.value);
                    if (stat.name === "receiving yards") aggregatedStats.receiving_yards = (aggregatedStats.receiving_yards || 0) + parseInt(stat.value.replace(/,/g, '')); // Receiving Yards
                    if (stat.name === "receiving touchdowns") aggregatedStats.receiving_touchdowns = (aggregatedStats.receiving_touchdowns || 0) + parseInt(stat.value);
                });
            }
        });
    });

    // Fill in missing stats with 0
    const requiredStats = [
        'pass_attempts', 'pass_completions', 'pass_yards', 'pass_touchdowns',
        'interceptions', 'rush_attempts', 'rush_yards', 'rush_touchdowns',
        'fumbles_lost', 'receptions', 'receiving_yards', 'receiving_touchdowns',
        'sacks'
    ];

    requiredStats.forEach(stat => {
        if (!aggregatedStats[stat]) {
            aggregatedStats[stat] = 0;
        }
    });

    return aggregatedStats;
};


export const fetchQuarterbackDetails = async (player) => {
    const quarterbackFromJson = offensivePlayers.QB.find(qb => parseInt(qb.id) === parseInt(player.player.id));

    if (!quarterbackFromJson) {
        console.error('Quarterback not found in playersOff.json:', player.player.id);
        return null;
    }

    const preExistingInjuries = fetchPreExistingInjuries(player.player.id);

    // Get missed game percentage for the player
    const missedGameInfo = missedGames.find(mg => mg.id === player.player.id);
    const missedGamePercentage = missedGameInfo ? missedGameInfo.percentage_missed : null;
    const missedGamePercentageThree = missedGameInfo ? missedGameInfo.percentage_missed_last_3_years : null;

    return {
        ...quarterbackFromJson,
        team: quarterbackFromJson.team || "Free Agent",
        preExistingInjuries,
        missedGamePercentage,
        missedGamePercentageThree  // Include missed game percentage
    };
};


export const fetchRunningBackDetails = async (player) => {
    const runningBackFromJson = offensivePlayers.RB.find(rb => parseInt(rb.id) === parseInt(player.player.id));

    if (!runningBackFromJson) {
        console.error('Running back not found in playersOff.json:', player.player.id);
        return null;
    }

    const preExistingInjuries = fetchPreExistingInjuries(player.player.id);

    // Get missed game percentage for the player
    const missedGameInfo = missedGames.find(mg => mg.id === player.player.id);
    const missedGamePercentage = missedGameInfo ? missedGameInfo.percentage_missed : null;
    const missedGamePercentageThree = missedGameInfo ? missedGameInfo.percentage_missed_last_3_years : null;

    return {
        ...runningBackFromJson,
        team: runningBackFromJson.team || "Free Agent",
        preExistingInjuries,
        missedGamePercentage,
        missedGamePercentageThree  // Include missed game percentage
    };
};



// Fetch Wide Receivers (WR)
export const fetchWideReceiverDetails = async (player) => {
    const wideReceiverFromJson = offensivePlayers.WR.find(wr => parseInt(wr.id) === parseInt(player.player.id));

    if (!wideReceiverFromJson) {
        console.error('Wide receiver not found in playersOff.json:', player.player.id);
        return null;
    }

    const preExistingInjuries = fetchPreExistingInjuries(player.player.id);

    // Get missed game percentage for the player
    const missedGameInfo = missedGames.find(mg => mg.id === player.player.id);
    const missedGamePercentage = missedGameInfo ? missedGameInfo.percentage_missed : null;
    const missedGamePercentageThree = missedGameInfo ? missedGameInfo.percentage_missed_last_3_years : null;

    return {
        ...wideReceiverFromJson,
        team: wideReceiverFromJson.team || "Free Agent",
        preExistingInjuries,
        missedGamePercentage,
        missedGamePercentageThree  // Include missed game percentage
    };
};


// Fetch Tight Ends (TE)
export const fetchTightEndDetails = async (player) => {
    const tightEndFromJson = offensivePlayers.TE.find(te => parseInt(te.id) === parseInt(player.player.id));

    if (!tightEndFromJson) {
        console.error('Tight end not found in playersOff.json:', player.player.id);
        return null;
    }

    const preExistingInjuries = fetchPreExistingInjuries(player.player.id);

    // Get missed game percentage for the player
    const missedGameInfo = missedGames.find(mg => mg.id === player.player.id);
    const missedGamePercentage = missedGameInfo ? missedGameInfo.percentage_missed : null;
    const missedGamePercentageThree = missedGameInfo ? missedGameInfo.percentage_missed_last_3_years : null;

    return {
        ...tightEndFromJson,
        team: tightEndFromJson.team || "Free Agent",
        preExistingInjuries,
        missedGamePercentage,
        missedGamePercentageThree  // Include missed game percentage
    };
};




export const fetchPlayerDetails = async (player) => {
    // Check all offensive players (QB, RB, WR, TE) from playersOff.json
    const allOffensivePlayers = Object.values(offensivePlayers).flatMap(position => position);
    const playerFromJson = allOffensivePlayers.find(p => p.id === player.player.id);

    if (playerFromJson) {
        // console.log('Using player details from playersOff.json for player:', player.player.id);
        return { ...playerFromJson, team: playerFromJson.team || "Unknown" };  // Ensure team is included
    }

    // If player not found in the JSON, return null
    console.error('Player not found in playersOff.json:', player.player.id);
    return null;
};

export const fetchPlayerStatistics = async (player) => {
    const offensivePlayerIds = getOffensivePlayerIds();

    // Check if the player is an offensive player
    if (!offensivePlayerIds.includes(player.id)) {
        console.warn(`Player with ID: ${player.id} is not an offensive player`);
        return null; // Return null to exclude this player
    }

    const cacheKey = `${player.id}`;
    if (playerStatsCache[cacheKey]) {
        return playerStatsCache[cacheKey];  // Return cached stats
    }

    const injuryDetails = await fetchInjuryDetails(player.id);

    // Fetch stats for 2023 and 2024 asynchronously
    const aggregatedStats2023 = await fetchPlayerStatsFromJson(player.id, 2023);
    const aggregatedStats2024 = await fetchPlayerStatsFromJson(player.id, 2024);

    // Find the player's raw statistics from the local JSON file for normalization
    const playerStatsData = playerStatistics.flat().find(stat => stat.player.id === player.id);

    if (!playerStatsData) {
        return null; // Return null if no stats are found
    }

    // Normalize the player stats for prediction
    const normalizedStats = mapAndNormalizePlayerStatistics(playerStatsData);

    // Cache the result with injury details
    playerStatsCache[cacheKey] = {
        displayStats2023: aggregatedStats2023,
        displayStats2024: aggregatedStats2024,
        normalizedStats: normalizedStats,
        injury: injuryDetails  // Include injury details without nullifying stats
    };

    return playerStatsCache[cacheKey];
};





export const isOffensivePlayer = (position) => ['QB', 'RB', 'WR', 'TE'].includes(position);

export const fetchTeamName = async (teamId) => {
    if (!teamId) {
        console.error('Team ID is undefined');
        return null;
    }

    const url = `https://api-american-football.p.rapidapi.com/teams?id=${teamId}`;
    const options = {
        method: 'GET',
        headers: {
            'X-RapidAPI-Key': '0be998d966mshcc7288b1c531576p19e5b0jsn08fc430b251e',
            'X-RapidAPI-Host': 'api-american-football.p.rapidapi.com'
        }
    };

    try {
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error(`API call failed with status: ${response.status}`);
        }
        const teamData = await response.json();
        return teamData.response && teamData.response.length > 0 ? teamData.response[0].name : null;
    } catch (error) {
        console.error('Error fetching team name:', error);
        return null;
    }
};

export const fetchTeamLogo = async (teamId) => {
    if (!teamId) {
        console.error('Team ID is undefined');
        return null;
    }

    const url = `https://api-american-football.p.rapidapi.com/teams?id=${teamId}`;
    const options = {
        method: 'GET',
        headers: {
            'X-RapidAPI-Key': '0be998d966mshcc7288b1c531576p19e5b0jsn08fc430b251e',
            'X-RapidAPI-Host': 'api-american-football.p.rapidapi.com'
        }
    };

    try {
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error(`API call failed with status: ${response.status}`);
        }
        const teamData = await response.json();
        return teamData.response && teamData.response.length > 0 ? teamData.response[0].logo : null;
    } catch (error) {
        console.error('Error fetching team logo:', error);
        return null;
    }
};







export const parseStatistics = (statsData) => {
    const stats = {};

    // Iterate over the teams array directly in the local JSON data
    statsData.teams.forEach(teamDetail => {
        teamDetail.groups.forEach(group => {
            group.statistics.forEach(stat => {
                if (stat.value !== null && stat.value !== undefined) {
                    stats[stat.name] = parseFloat(stat.value); // Ensure values are numbers
                }
            });
        });
    });

    return stats;
};


export const mapAndNormalize = (value, name) => {
    const mapping = {
        'rush_attempts': 100,
        'rush_yards': 500,
        'rush_touchdowns': 10,
        'pass_attempts': 100,
        'pass_completions': 100,
        'pass_yards': 1000,
        'pass_touchdowns': 10,
        'interceptions': 5,
        'receptions': 50,
        'receiving_yards': 500,
        'receiving_touchdowns': 10,
        'fumbles_lost': 5
    };

    const normalizedValue = parseFloat((value / mapping[name]).toFixed(2));

    return isNaN(normalizedValue) ? 0 : normalizedValue;
};

export const mapAndNormalizePlayerStatistics = (statsData) => {
    const stats = parseStatistics(statsData);

    const mappedAndNormalizedStats = {
        rush_attempts: mapAndNormalize(stats.rush_attempts, 'rush_attempts'),
        rush_yards: mapAndNormalize(stats.rush_yards, 'rush_yards'),
        rush_touchdowns: mapAndNormalize(stats.rush_touchdowns, 'rush_touchdowns'),
        pass_attempts: mapAndNormalize(stats.pass_attempts, 'pass_attempts'),
        pass_completions: mapAndNormalize(stats.pass_completions, 'pass_completions'),
        pass_yards: mapAndNormalize(stats.pass_yards, 'pass_yards'),
        pass_touchdowns: mapAndNormalize(stats.pass_touchdowns, 'pass_touchdowns'),
        interceptions: mapAndNormalize(stats.interceptions, 'interceptions'),
        receptions: mapAndNormalize(stats.receptions, 'receptions'),
        receiving_yards: mapAndNormalize(stats.receiving_yards, 'receiving_yards'),
        receiving_touchdowns: mapAndNormalize(stats.receiving_touchdowns, 'receiving_touchdowns'),
        fumbles_lost: mapAndNormalize(stats.fumbles_lost, 'fumbles_lost')
    };

    return mappedAndNormalizedStats;
}

export const fetchPlayersForTeam = async (gameId, teamId) => {
    // console.log("Fetching players for team:", teamId);

    if (!teamId) {
        throw new Error('Team ID is undefined');
    }

    const url = `https://api-american-football.p.rapidapi.com/games/statistics/players?id=${gameId}&team=${teamId}`;
    const options = {
        method: 'GET',
        headers: {
            'X-RapidAPI-Key': '0be998d966mshcc7288b1c531576p19e5b0jsn08fc430b251e',
            'X-RapidAPI-Host': 'api-american-football.p.rapidapi.com'
        }
    };

    try {
        const response = await fetch(url, options);
        const data = await response.json();

        if (data.results > 0) {
            // Flatten the player list from the API response
            const players = data.response.flatMap(team => team.groups.flatMap(group => group.players));
            // console.log('Fetched players for team:', players);

            // Get offensive player IDs from playersOff.json
            const offensivePlayerIds = getOffensivePlayerIds();

            // Filter players to only include offensive players
            const offensivePlayers = players.filter(player => offensivePlayerIds.includes(player.player.id));

            // console.log('Filtered offensive players:', offensivePlayers);

            return offensivePlayers;
        } else {
            console.error('No player statistics found for this game.');
            return [];
        }
    } catch (error) {
        console.error('Error fetching player statistics for team:', error);
        return [];
    }
};

