import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import imgfooter from '../images/injsurfooter.jpg';

const Footer = () => {
  return (
    <footer 
    className="bg-cover bg-top py-8 px-4 sm:px-10 md:px-20 lg:px-40 xl:px-60"

      style={{ backgroundImage: `url(${imgfooter})`, backgroundPosition: 'top' }}

    >
      <div className="flex flex-col md:flex-row justify-between items-center text-center md:text-left text-green-950 ">
        <div className="mb-6 md:mb-0 mt-6">
          <p>Patent Pending. All Rights Reserved INJSUR.AI™ 2024</p>
          <div className="mt-2">
            <a href="/terms-of-use" className="hover:underline">TERMS OF USE</a> | 
            <a href="/privacy-policy" className="hover:underline ml-2">PRIVACY POLICY</a> 
          </div>
          </div>
        <div className="mb-6 md:mb-0">
          <p className="font-bold mb-2">FOLLOW</p>
          <div className="flex space-x-4">
            <a href="#" className="text-green-950 text-xl hover:text-gray-500 transition duration-200">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="#" className="text-green-950 text-xl hover:text-gray-500 transition duration-200">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="#" className="text-green-950 text-xl hover:text-gray-500 transition duration-200">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
