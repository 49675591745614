import React, { useState, useEffect } from 'react';
import {
    fetchQuarterbackDetails,
    fetchRunningBackDetails,
    fetchWideReceiverDetails,
    fetchPlayerStatistics,
} from './utilityFunctions';
import FeaturedPlayerCard from './FeaturedPlayerCard'; // Use the card for featured players
import playerImage1 from '../images/pm.png';
import playerImage2 from '../images/th.png';
import playerImage3 from '../images/cm.png';
import playerImage4 from '../images/ja.png';
import playerImage5 from '../images/jj.png';
import logo from '../images/injsurlogo2.png';
import offensivePlayers from './combined_players.json'; // Import playersOff.json

// Static player data for images and frailty scores
const playerData = [
    {
        playerImage: playerImage1,
        id: 1197,
        logo: logo,
        teamName: 'CHIEFS',
        frailtyScore: '5.35',
        playerName: 'PATRICK MAHOMES',
        position: 'QB',
    },
    {
        playerImage: playerImage2,
        id: 1792,
        logo: logo,
        teamName: 'DOLPHINS',
        frailtyScore: '7.3',
        playerName: 'TYREEK HILL',
        position: 'WR',
    },
    {
        playerImage: playerImage3,
        id: 1348,
        logo: logo,
        teamName: '49ERS',
        frailtyScore: '8.37',
        playerName: 'CHRISTIAN MCCAFFERY',
        position: 'RB',
    },
    {
        playerImage: playerImage4,
        id: 1414,
        logo: logo,
        teamName: 'BILLS',
        frailtyScore: '8.65',
        playerName: 'JOSH ALLEN',
        position: 'QB',
    },
    {
        playerImage: playerImage5,
        id: 2319,
        logo: logo,
        teamName: 'VIKINGS',
        frailtyScore: '6.4',
        playerName: 'JUSTIN JEFFERSON',
        position: 'WR',
    },
];

const PlayerCardsSection = ({ predictFrailty, calculateAdjustedFrailtyScore }) => {
    const [fetchedPlayerStats, setFetchedPlayerStats] = useState([]);

    useEffect(() => {
        const fetchPlayerStats = async () => {
            // console.log('Fetching stats for featured players');

            const featuredPlayers = [
                offensivePlayers.QB.find((player) => player.id === 1197), // Patrick Mahomes
                offensivePlayers.WR.find((player) => player.id === 1792), // Tyreek Hill
                offensivePlayers.RB.find((player) => player.id === 1348), // Christian McCaffrey
                offensivePlayers.QB.find((player) => player.id === 1414), // Josh Allen
                offensivePlayers.WR.find((player) => player.id === 2319), // Justin Jefferson
            ];

            const validPlayers = featuredPlayers.filter((player) => player !== undefined);
            // console.log('Valid featured players:', validPlayers);

            const playerStats = await Promise.all(
                validPlayers.map(async (player) => {
                    let playerDetails;
            
                    switch (player.position) {
                        case 'QB':
                            playerDetails = await fetchQuarterbackDetails({ player });
                            break;
                        case 'RB':
                            playerDetails = await fetchRunningBackDetails({ player });
                            break;
                        case 'WR':
                            playerDetails = await fetchWideReceiverDetails({ player });
                            break;
                        default:
                            playerDetails = null;
                    }
            
                    // console.log('Fetched player details:', playerDetails);  // Add this log
            
                    if (playerDetails) {
                        const statsResult = await fetchPlayerStatistics(player);
                        // console.log('Fetched stats for player:', statsResult);  // Add this log
            
                        return {
                            id: player.id,
                            stats2023: statsResult?.displayStats2023 || {},
                            stats2024: statsResult?.displayStats2024 || {},
                            injury: statsResult?.injury,
                            age: playerDetails.age,  // Ensure age is present
                            height: playerDetails.height,  // Ensure height is present
                            weight: playerDetails.weight,  // Ensure weight is present
                            experience: playerDetails.experience,  // Ensure experience is present
                            preExistingInjuries: playerDetails.preExistingInjuries,
                            missedGamePercentage: playerDetails.missedGamePercentage,
                            missedGamePercentageThree: playerDetails.missedGamePercentageThree

                        };
                    }
            
                    return null;
                })
            );
            

            const filteredPlayerStats = playerStats.filter((player) => player !== null);
            // console.log('Filtered player stats:', filteredPlayerStats);

            setFetchedPlayerStats(filteredPlayerStats);
        };

        fetchPlayerStats();
    }, [predictFrailty, calculateAdjustedFrailtyScore]);


    // Merge static data with fetched player stats
    const mergedPlayerData = playerData.map(staticPlayer => {
        const fetchedPlayer = fetchedPlayerStats.find(
            player => player.id === staticPlayer.id
        );

        if (fetchedPlayer) {
            // console.log('Fetched player details merged with static player data:', fetchedPlayer);
        } else {
            console.log('No fetched data for player with ID:', staticPlayer.id);
        }

        return {
            ...staticPlayer,
            stats2023: fetchedPlayer?.stats2023 || {},  // Ensure fetched stats are used
            stats2024: fetchedPlayer?.stats2024 || {},  // Ensure fetched stats are used
            injury: fetchedPlayer?.injury || null,      // Pass injury data
            age: fetchedPlayer?.age || staticPlayer.age, // Use static data or fetched
            height: fetchedPlayer?.height || staticPlayer.height,
            weight: fetchedPlayer?.weight || staticPlayer.weight,
            experience: fetchedPlayer?.experience || staticPlayer.experience,
            preExistingInjuries: fetchedPlayer?.preExistingInjuries || staticPlayer.preExistingInjuries,
            missedGamePercentage: fetchedPlayer?.missedGamePercentage,
            missedGamePercentageThree: fetchedPlayer?.missedGamePercentageThree

        };
    });

    return (
        <section className="h-auto py-12 relative flex flex-col items-center justify-center bg-cover text-white ml-4 mr-8" style={{ fontFamily: 'Montserrat, sans-serif' }}>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5 3xl:grid-cols-5 gap-12">
                {mergedPlayerData.map((player, index) => (
                    <FeaturedPlayerCard
                        key={index}
                        playerId={player.id}
                        playerImage={player.playerImage}
                        logo={player.logo}
                        teamName={player.teamName}
                        frailtyScore={player.frailtyScore}
                        playerName={player.playerName}
                        position={player.position}
                        playerStats2023={player.stats2023}
                        playerStats2024={player.stats2024}
                        injury={player.injury}
                        age={player.age}
                        height={player.height}
                        weight={player.weight}
                        experience={player.experience}
                        preExistingInjuries={player.preExistingInjuries}
                        missedGamePercentage={player.missedGamePercentage}
                        missedGamePercentageThree={player.missedGamePercentageThree}
                    />
                ))}
            </div>
        </section>
    );
};

export default PlayerCardsSection;
