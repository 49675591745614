import React, { createContext, useState, useEffect } from 'react';
import * as tf from '@tensorflow/tfjs';
import { modelConfig } from '../models/modelConfig';
import weightsFile from '../models/injsur.weights.bin';

export const FrailtyModelContext = createContext();

export const FrailtyModelProvider = ({ children }) => {
    const [model, setModel] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    // Use for live 
    // useEffect(() => {
    //     const loadModel = async () => {
    //         try {
    //             // Load model from the same URL as the first code
    //             const loadedModel = await tf.loadLayersModel('https://injsur.app/test_model/injsur.json', { weightPathPrefix: 'https://injsur.app/test_model/' });

    //             setModel(loadedModel);
    //             setLoading(false);
    //         } catch (err) {
    //             setError(`Error loading model: ${err.message}`);
    //             setLoading(false);
    //         }
    //     };


    //     loadModel();
    // }, []);


    // Use for test
    useEffect(() => {
        const loadModel = async () => {
            try {
                const response = await fetch(weightsFile);
                const weightsArrayBuffer = await response.arrayBuffer();

                const loadedModel = await tf.loadLayersModel(tf.io.fromMemory(
                    modelConfig.modelTopology,
                    [{ data: weightsArrayBuffer, name: 'weights' }]
                ));

                setModel(loadedModel);
                setLoading(false);
            } catch (err) {
                setError(`Error loading model: ${err.message}`);
                setLoading(false);
            }
        };

        loadModel();
    }, []);


    return (
        <FrailtyModelContext.Provider value={{ model, loading, error }}>
            {children}
        </FrailtyModelContext.Provider>
    );
};
