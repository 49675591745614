import React, { useContext, useCallback, useState, useEffect, useRef } from 'react';
import * as tf from '@tensorflow/tfjs'; // Import TensorFlow.js
import { FrailtyModelContext } from './FrailtyModelContext';
import Team from './Team';
import UserHeader from './UserHeader';

const FrailtyTeams = ({ teamId, teamName, teamLogo, onBack }) => {
    const { model, loading, error } = useContext(FrailtyModelContext); // Access the model from context
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal open/close
    const modalRef = useRef(null); // Ref to track the modal element

    const predictFrailty = useCallback(async (playerDetails, playerStats) => {
        if (!model) {
            console.error("Model is not loaded yet. Cannot predict frailty.");
            return null;
        }

        try {
            const data = tf.tensor2d([Object.values(playerStats)], [1, Object.keys(playerStats).length]);
            const predictions = model.predict(data);
            const frailtyScoreTensor = await predictions.data();
            const frailtyScore = frailtyScoreTensor[0] * 10;
            return frailtyScore.toFixed(1);
        } catch (error) {
            console.error("Error during frailty prediction:", error);
            return null;
        }
    }, [model]);

    const calculateAdjustedFrailtyScore = useCallback((frailtyScore, position) => {
        let adjustedFrailtyScore = parseFloat(frailtyScore);
        const minOriginal = 0.0;
        const maxOriginal = 6.0;
        const minTarget = 1.0;
        const maxTarget = 10.0;

        adjustedFrailtyScore = Math.min(Math.max(adjustedFrailtyScore, minOriginal), maxOriginal);
        const mappedFrailtyScore = minTarget + ((adjustedFrailtyScore - minOriginal) * (maxTarget - minTarget) / (maxOriginal - minOriginal));

        return mappedFrailtyScore;
    }, []);

    // Handle click outside the modal to close it
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isModalOpen && modalRef.current && !modalRef.current.contains(event.target)) {
                setIsModalOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isModalOpen]);

    if (loading) {
        return <div>Loading model...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <div className="flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-4 mb-4 p-10">
                <button
                    onClick={onBack}
                    className="bg-zinc-900 hover:bg-zinc-950 text-white font-bold py-3 px-8 rounded"
                >
                    Back
                </button>

                <img src={teamLogo} alt={`${teamName} logo`} style={{ width: '50px', height: '50px' }} />

                <h2 className="text-xl font-bold">
                    {teamName} Player Frailty Cards
                </h2>

                <input
                    type="text"
                    placeholder="Search Players..."
                    className="w-full md:w-1/3 p-2 border rounded-md"
                    onChange={(e) => setSearchTerm(e.target.value)}
                />

                <button
                    onClick={() => setIsModalOpen(true)} // Open the modal on click
                    className="bg-zinc-900 hover:bg-zinc-950 font-bold hover:text-[#d8bf82] text-white p-2 rounded-md"
                >
                    How Frailty Scores Work
                </button>
            </div>

            {/* Team component */}
            <Team
                teamId={teamId}
                teamName={teamName}
                predictFrailty={predictFrailty}
                calculateAdjustedFrailtyScore={calculateAdjustedFrailtyScore}
                searchTerm={searchTerm}
            />

            {/* Modal */}
            {isModalOpen && (
                <div
                    className="fixed inset-0 flex items-center justify-center z-50"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                >
                    <div ref={modalRef} className="relative bg-white rounded-lg p-8 max-w-lg w-full mx-4 h-4/5 overflow-y-auto">
                        <h2 className="text-2xl font-bold mb-4">How Frailty Scores Work</h2>
                        <p className="mb-4">
                            Injsur.ai is a cutting-edge application that uses advanced machine learning to assess the frailty of NFL players. Our proprietary model, trained on extensive player data dating back to 2005, provides our users with crucial insights into the statistical probability of player health and performance.
                        </p>
                        <p className="mb-4">
                            The Injsur model is built on a comprehensive dataset including player demographics (age, height, weight, position), performance statistics (yards, touchdowns, tackles, etc.), current physical performance metrics, detailed injury history, workload data (snap counts, practice participation) and more...
                        </p>
                        <p className="mb-4">
                            Our proprietary machine learning model is developed using state-of-the-art techniques to produce an accurate Frailty Score showing the level of risk of injury for each player. Key features of Injsur.ai include data preprocessing and feature engineering with semantics for injury type, severity, etc., with continuous model updates to capture evolving trends in the sport with recursive backtesting and constant accuracy calibration based on real-time data.
                        </p>
                        <p className="mb-4">
                            The Injsur algorithm inputs current player data into the Injsur web app. Then the Injsur model processes the data and outputs a Frailty Score on a scale of 0-10.
                        </p>
                        <ul className="mb-4 list-disc list-inside">
                            <li className="font-bold">
                                0-3: <span className="ml-1 font-bold text-green-500">Low risk</span>
                            </li>
                            <li className="font-bold">
                                4-7: <span className="ml-1 font-bold text-yellow-600">Moderate risk</span>
                            </li>
                            <li className="font-bold">
                                8-10: <span className="ml-1 font-bold text-red-500">High risk</span>
                            </li>
                        </ul>
                        <button
                            onClick={() => setIsModalOpen(false)} // Close the modal on click
                            className="bg-red-500 text-white p-2 rounded-md mt-4"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FrailtyTeams;
