import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faBars, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import logo from '../images/injsurlogo2.png'; // Adjust the path based on your project structure

const UserHeader = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getActiveTab = () => {
    if (location.pathname === '/get-insights') {
      return 'GET INSIGHTS'
    }
    else if (location.pathname === '/games') {
      return 'GAMES';
    }
    else if (location.pathname === '/teams') {
      return 'TEAMS';
    }
    else if (location.pathname === '/qb') {
      return 'QB';
    }
    else if (location.pathname === '/rb') {
      return 'RB';
    }
    else if (location.pathname === '/wr') {
      return 'WR';
    }
    else if (location.pathname === '/te') {
      return 'TE';
    }
    else if (location.pathname === '/account') {
      return 'ACCOUNT';
    }
    else {
      return 'HOME';
    }
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const handleAccountSettings = (e) => {
    e.preventDefault(); // Prevent default anchor action
    navigate("/account", { state: { accountType: 'Player/Parent' } });
    setShowDropdown(false);
  };

  return (
    <div
      className={`sticky top-0 w-full z-50  px-4 md:px-12 flex items-center justify-between transition-all duration-300 bg-zinc-900 `}

    // className={`sticky top-0 w-full z-50 py-4 md:py-2 px-4 md:px-12 flex items-center justify-between transition-all duration-300  ${isScrolled ? 'bg-zinc-900' : 'bg-transparent'}`}
    >

      <div className="flex items-center space-x-4 md:space-x-6">
        <Link to="/">
          <div className="flex items-center">
            <img src={logo} alt="Logo" className="h-20 md:h-20 " />
            <span className="text-[#fbf4e4] text-sm pt-10 pr-6 font-bold">™</span>
          </div>
        </Link>
        {/* Navigation Links */}
        <div className="hidden xl:flex space-x-6 " style={{ fontFamily: 'Montserrat, sans-serif' }}>
          <Link
            to="/home"
            className={`text-lg font-semibold border-b-2 transition duration-200 ${getActiveTab() === 'HOME'
              ? 'text-[#d8bf82] border-[#d8bf82]'
              : 'text-white border-transparent hover:border-gray-300 hover:text-gray-300'
              }`}
          >
            HOME
          </Link>

          <Link
            to="/games"
            className={`text-lg font-semibold border-b-2 transition duration-200 ${getActiveTab() === 'GAMES'
              ? 'text-[#d8bf82] border-[#d8bf82]'
              : 'text-white border-transparent hover:border-gray-300 hover:text-gray-300'
              }`}
          >
            GAMES
          </Link>
          <Link
            to="/teams"
            className={`text-lg font-semibold border-b-2 transition duration-200 ${getActiveTab() === 'TEAMS'
              ? 'text-[#d8bf82] border-[#d8bf82]'
              : 'text-white border-transparent hover:border-gray-300 hover:text-gray-300'
              }`}
          >
            TEAMS
          </Link>


          <Link
            to="/qb"
            className={`text-lg font-semibold border-b-2 transition duration-200 ${getActiveTab() === 'QB'
              ? 'text-[#d8bf82] border-[#d8bf82]'
              : 'text-white border-transparent hover:border-gray-300 hover:text-gray-300'
              }`}
          >
            QB
          </Link>
          <Link
            to="/rb"
            className={`text-lg font-semibold border-b-2 transition duration-200 ${getActiveTab() === 'RB'
              ? 'text-[#d8bf82] border-[#d8bf82]'
              : 'text-white border-transparent hover:border-gray-300 hover:text-gray-300'
              }`}
          >
            RB
          </Link>
          <Link
            to="/wr"
            className={`text-lg font-semibold border-b-2 transition duration-200 ${getActiveTab() === 'WR'
              ? 'text-[#d8bf82] border-[#d8bf82]'
              : 'text-white border-transparent hover:border-gray-300 hover:text-gray-300'
              }`}
          >
            WR
          </Link>
          <Link
            to="/te"
            className={`text-lg font-semibold border-b-2 transition duration-200 ${getActiveTab() === 'TE'
              ? 'text-[#d8bf82] border-[#d8bf82]'
              : 'text-white border-transparent hover:border-gray-300 hover:text-gray-300'
              }`}
          >
            TE
          </Link>

        </div>

        {/* Drawer Icon for Mobile */}

      </div>

      {/* Right Side: Social Media and Sign Out Button */}
      <div className="flex items-center space-x-4 md:space-x-12 ">
        <div className="hidden lg:flex space-x-4 md:space-x-8 items-center">

          <a href="#" className="text-white text-xl hover:text-gray-300 transition duration-200">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a href="#" className="text-white text-xl hover:text-gray-300 transition duration-200">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="#" className="text-white text-xl hover:text-gray-300 transition duration-200">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <button
            className="bg-transparent font-bold text-white text-lg px-4 md:px-8 py-2 rounded-full hover:bg-white hover:text-black transition duration-200"
            style={{
              fontFamily: 'Montserrat, sans-serif',
              backgroundColor: '#d8bf82',
              color: 'black',
              borderColor: 'white',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#a78c5d')}
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#d8bf82')}
            onClick={handleLogout}
          >
            Sign Out
          </button>
        </div>
        <div className="hidden md:inline-flex  relative group">
          <button
            onClick={toggleDropdown}
            className="relative w-12 h-12 flex items-center justify-center hover:bg-zinc-700 -mx-8 -my-4 rounded-full"
          >
            <FontAwesomeIcon icon={faUser} size="xl" style={{ color: "#d8bf82" }} />
          </button>
          {showDropdown && (
            <div className="absolute right-0 mt-12 py-3 w-48 bg-white rounded-md shadow-xl z-20 border border-gray-400">
              <a href="#" className="flex justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={handleAccountSettings}>
                <span>Account Settings</span> <span>{'>'}</span>
              </a>
              {/* <a href="#" className="flex justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={handleGiveFeedback}>
                <span>Help & Feedback</span> <span>{'>'}</span>
              </a>
              <a href="#" className="flex justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={handleEmailPreferences}>
                <span>Preferences</span> <span>{'>'}</span>
              </a> */}
            </div>
          )}
        </div>
        <button className="xl:hidden text-white text-3xl pr-8" onClick={toggleDrawer}>
          <FontAwesomeIcon icon={isDrawerOpen ? faTimes : faBars} />
        </button>
      </div>

      {/* Drawer: Visible on Mobile */}
      {isDrawerOpen && (
        <div className="fixed top-0 left-0 h-screen w-full bg-zinc-900 bg-opacity-100 flex flex-col items-center z-50 transition-opacity duration-300 ease-in-out animate-fadeIn">
          <button className="absolute top-5 right-5 text-white text-3xl" onClick={toggleDrawer}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <img src={logo} alt="Logo" className="h-24 mb-8 mt-10" />
          <Link
            to="/home"
            onClick={toggleDrawer}
            className={`mb-2 text-white text-2xl font-semibold border-b-2 ${getActiveTab() === 'HOME' ? 'border-white' : 'border-transparent hover:border-white'
              } transition duration-200`}
          >
            HOME
          </Link>
          <Link
            to="/teams"
            className={`mb-2 text-white text-2xl font-semibold border-b-2 ${getActiveTab() === 'TEAMS' ? 'border-white' : 'border-transparent hover:border-white'
              } transition duration-200`}
          >
            TEAMS
          </Link>
          <Link
            to="/qb"
            className={`mb-2 text-white text-2xl font-semibold border-b-2 ${getActiveTab() === 'QB' ? 'border-white' : 'border-transparent hover:border-white'
              } transition duration-200`}
          >
            QB
          </Link>
          <Link
            to="/rb"
            className={`mb-2 text-white text-2xl font-semibold border-b-2 ${getActiveTab() === 'RB' ? 'border-white' : 'border-transparent hover:border-white'
              } transition duration-200`}
          >
            RB
          </Link>
          <Link
            to="/wr"
            className={`mb-2 text-white text-2xl font-semibold border-b-2 ${getActiveTab() === 'WR' ? 'border-white' : 'border-transparent hover:border-white'
              } transition duration-200`}
          >
            WR
          </Link>
          <Link
            to="/te"
            className={`mb-2 text-white text-2xl font-semibold border-b-2 ${getActiveTab() === 'TE' ? 'border-white' : 'border-transparent hover:border-white'
              } transition duration-200`}
          >
            TE
          </Link>
          <Link
            to="/account"
            className={`mb-2 text-white text-2xl font-semibold border-b-2 ${getActiveTab() === 'ACCOUNT' ? 'border-white' : 'border-transparent hover:border-white'
              } transition duration-200`}
          >
            ACCOUNT
          </Link>
          <Link
            to="/login"
            className="bg-transparent font-bold text-white text-lg px-4 mt-6 md:px-8 py-2 rounded-full hover:bg-white hover:text-black transition duration-200"
            style={{
              fontFamily: 'Montserrat, sans-serif',
              backgroundColor: '#d8bf82',
              color: 'black',
              borderColor: 'white',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#a78c5d')}
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#d8bf82')}
            onClick={handleLogout}
          >
            Sign Out
          </Link>




          <div className="flex space-x-4 items-center pt-10">
            <a href="#" className="text-white text-xl hover:text-gray-300 transition duration-200">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="#" className="text-white text-xl hover:text-gray-300 transition duration-200">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="#" className="text-white text-xl hover:text-gray-300 transition duration-200">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserHeader;
