import injuryWeights from './injury_weights.json'; // Import injury weights
import historicInjuries from './historic_injuries.json'; // Import historic injuries data
import aggregatedInjuries from './aggregated_injuries.json'; // Import aggregated injuries data
import missedGamesData from './missed_games.json'; // Import missed games data

// Utility function to check for fuzzy matches (basic includes match, can be replaced with more advanced fuzzy matchers)
const fuzzyMatchInjury = (injuryName, weightInjuryName) => {
    const injuryWords = injuryName.toLowerCase().split(/\s+/);
    const weightInjuryWords = weightInjuryName.toLowerCase().split(/\s+/);
    const commonWords = injuryWords.filter(word => weightInjuryWords.includes(word));
    return commonWords.length > 0;
};

// Position weights for career touches
const positionTouchWeights = {
    QB: 0.2,
    RB: 0.5,
    WR: 0.5,
    TE: 0.5
};

// Utility function to calculate injury-adjusted frailty score
export const calculateInjuryScore = (frailtyScore, playerDetails) => {
    const severityMultipliers = {
        1: 1,  // Severity 1 multiplier
        2: 1.25, // Severity 2 multiplier
        3: 1.5   // Severity 3 multiplier
    };

    const playerId = playerDetails.id;
    const position = playerDetails.position;

    // Log if the player is one of the specified players (2227 or 14854)
    if ([2227, 1853, 1050].includes(playerId)) {
        console.log(`Calculating injury score for player ID: ${playerId}, Position: ${position}`);
        console.log(`Original frailty score: ${frailtyScore}`);
    }

    const getPlayerInjuries = (playerId) => {
        const playerInjuries = [];

        // Get injuries from historic_injuries.json by player ID
        Object.keys(historicInjuries.players_by_year).forEach((year) => {
            historicInjuries.players_by_year[year].forEach((player) => {
                if (player.id === playerId.toString()) {
                    if (Array.isArray(player.injuries)) {
                        playerInjuries.push(...player.injuries);
                    } else if (typeof player.injuries === 'string') {
                        playerInjuries.push(player.injuries);
                    }
                }
            });
        });

        // Get injuries from aggregated_injuries.json by player ID
        aggregatedInjuries.forEach((player) => {
            if (player.id.toString() === playerId.toString()) {
                if (Array.isArray(player.injury_types)) {
                    playerInjuries.push(...player.injury_types);
                }
            }
        });

        // Remove duplicates and ensure that we return an array
        return [...new Set(playerInjuries)];
    };

    const playerInjuries = getPlayerInjuries(playerId);

    if (!playerInjuries || playerInjuries.length === 0 || !position) {
        return frailtyScore;
    }

    let totalImpact = 0;

    playerInjuries.forEach(injury => {
        const injuryData = injuryWeights.injuries.find(item =>
            fuzzyMatchInjury(injury, item.name)
        );

        if (injuryData) {
            injuryData.impacts.forEach((impact, severityLevel) => {
                // Ensure positionWeight exists and is a valid number
                const positionWeight = impact[position];

                if (typeof positionWeight === 'number' && positionWeight > 0) {  // Only apply positive weights
                    const severityMultiplier = severityMultipliers[severityLevel + 1] || 1.0;
                    const impactScore = positionWeight * severityMultiplier;
                    totalImpact += impactScore;

                    // Log each injury's impact if the player is 2227 or 14854
                    if (playerId === 2227 || playerId === 1050) {
                        console.log(`Injury: ${injury}, Severity: ${severityLevel + 1}, Position Weight: ${positionWeight}, Impact Score: ${impactScore}`);
                    }
                }
            });
        }
    });

    // Add impact from missed games percentage
    const missedGamesInfo = missedGamesData.find(player => player.id === playerId);
    if (missedGamesInfo) {
        const percentageMissed = missedGamesInfo.percentage_missed / 100;
        totalImpact += percentageMissed; // Adding the missed games percentage as a baseline multiplier

        // Log missed games impact if the player is 2227 or 14854
        if (playerId === 2227 || playerId === 1853) {
            console.log(`Missed Games Percentage for player ID ${playerId}: ${percentageMissed}`);
        }

        // Add impact from career touches using position-based weights
        const careerTouches = missedGamesInfo.career_touches;
        const touchWeight = positionTouchWeights[position] || 0; // Default to 0 if position not listed
        const touchesImpact = touchWeight * careerTouches / 1000; // Example scaling factor for career touches
        totalImpact += touchesImpact;

        if (playerId === 2227 || playerId === 1853) {
            console.log(`Career Touches Impact for player ID ${playerId}: ${touchesImpact}`);
        }
    }

    if (totalImpact > 0) {
        const adjustedScore = Math.min(frailtyScore + totalImpact, 9.5);

        // Log the final adjusted score if the player is 2227 or 14854
        if (playerId === 2227 || playerId === 1853) {
            console.log(`Total Impact for player ID ${playerId}: ${totalImpact}`);
            console.log(`Adjusted frailty score for player ID ${playerId}: ${Math.min(adjustedScore, 9.5)}`);
        }

        return Math.min(adjustedScore, 9.5);  // Cap at 9.5
    }

    // Log the original frailty score if no impact was found
    if (playerId === 2227 || playerId === 1853) {
        console.log(`No positive impact for player ID ${playerId}, returning original frailty score: ${frailtyScore}`);
    }

    return frailtyScore;
};
