import { CognitoUserPool } from 'amazon-cognito-identity-js';


const deactivateAccount = async (username, token, onDeactivated, onError) => {


    const poolData = {
        UserPoolId: 'us-west-1_O3eTJMcDB',
        ClientId: '3gq8phnk4dv05ce2rjdr8gb8o3'
    };

    const userPool = new CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
        onError("User is not authenticated");
        return;
    }

    try {
        const session = await getSession(cognitoUser);
        const jwtToken = session.getIdToken().getJwtToken(); // Extract JWT token from the session
        const deactivationResponse = await deactivateUser(username, token); // Use token for deactivation
        await deleteUser(cognitoUser);

        const subscriptionId = deactivationResponse.subscriptionId;
        if (subscriptionId) {
            await cancelStripeSubscription(subscriptionId, jwtToken); // Use jwtToken for Stripe cancellation
        }
        onDeactivated({ message: 'Account deleted successfully', statusCode: 200 });
    } catch (error) {
        console.error("Error in deactivateAccount:", error);
        onError({
            message: error.message || "Unknown error occurred",
            statusCode: error.statusCode || 500
        });
    }
};

const getSession = (cognitoUser) => {

    return new Promise((resolve, reject) => {
        cognitoUser.getSession((err, session) => {
            if (err) reject(new Error("Error fetching session: " + err.message));
            else if (!session.isValid()) reject(new Error("Session is not valid"));
            else resolve(session);
        });
    });
};

const deactivateUser = async (username, token) => {
    const response = await fetch(`https://yxuw0z3q57.execute-api.us-west-1.amazonaws.com/prod/deactivateUser/${username}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });

    if (!response.ok) {
        const errorText = await response.text(); // Parse the error body as text
        throw new Error(errorText || `HTTP error! status: ${response.status}`);
    }

    return response.json();
};



const deleteUser = (cognitoUser) => {
    return new Promise((resolve, reject) => {
        cognitoUser.deleteUser((err) => {
            if (err) reject(new Error("Error deleting Cognito user: " + err.message));
            else resolve();
        });
    });
};

const cancelStripeSubscription = async (subscriptionId, jwtToken) => {
    const response = await fetch(`https://bb2b9p5bpk.execute-api.us-west-1.amazonaws.com/prod/cancelStripeSubscription/${subscriptionId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,
        },
    });

    if (!response.ok) {
        throw new Error(`Failed to cancel Stripe subscription, status: ${response.status}`);
    }
};

export default deactivateAccount;
