import React from 'react';
import { Link } from 'react-router-dom';
import '../index.css';
import PlayerCardsSection from './PlayerCardsSection';

import Header from './Header';
import Footer from './Footer';
import img1 from '../images/greenBackground.jpeg'; // Adjust the path based on your project structure
import img2 from '../images/injsur2.jpeg'; // Adjust the path based on your project structure
import img3 from '../images/injsur3.jpg';
import img4 from '../images/injsur4.png';
import img5 from '../images/injsur5.jpg';
import img6 from '../images/injsur6.jpg';
import img7 from '../images/injsur7.jpg';
import img8 from '../images/injsur8.jpg';
import img9 from '../images/injsur9.jpg';
import img10 from '../images/injsur10.jpg';
import imgfootball from '../images/injsurFootball.jpg';
import imgmgm from '../images/mgm2.png';
import imgbet365 from '../images/bet3652.png';
import imgfanatics from '../images/fanatics2.png';
import imgcaesars from '../images/caesars2.png';
import CookieBanner from './CookieBanner';



const Home = () => {
    return (
        <div className="relative min-h-screen bg-transparent">
            <Header />


            {/* Main Content */}
            <div className="relative z-10">
                {/* Section 1 */}
                <section className="min-h-screen relative flex items-center justify-center bg-cover bg-center" style={{ backgroundImage: `url(${img1})`, backgroundPosition: 'center 20%' }}>
                    <div className="text-center text-white px-4 flex flex-col items-center justify-start h-full mb-36">
                        <h1
                            className="text-[10vw] sm:text-[8vw] md:text-[7vw] lg:text-[6vw] xl:text-[5vw] font-bold mb-10 lg:mx-80 animate-fade-up"
                            style={{ fontFamily: 'Montserrat, sans-serif' }}
                        >
                            A.I. FRAILTY SCORES
                        </h1>

                        {/* Line below the h1 */}
                        <span
                            style={{
                                display: 'block',
                                margin: '10px auto',
                                height: '0.5px',
                                width: '65%',
                                backgroundColor: '#d8bf82',
                            }}
                            className="animate-fade-up"
                        />

                        <p
                            className="text-xl sm:text-2xl md:text-4xl mb-4 max-w-6xl mx-auto animate-fade-up"
                            style={{ fontFamily: 'Montserrat, sans-serif', color: '#d8bf82' }}
                        >
                            CHANGING THE GAME OF <br className="block md:hidden" />
                            FANTASY SPORTS GAMBLING <br />
                            PREDICTIVE ARTIFICIAL INTELLIGENCE
                        </p>


                        {/* Line below the p */}
                        <span
                            style={{
                                display: 'block',
                                margin: '7px auto',
                                height: '0.5px',
                                width: '65%',
                                backgroundColor: '#d8bf82',
                            }}
                            className="animate-fade-up"
                        />

                        <Link to="/about">
                            <button
                                className="font-bold mt-10 sm:mt-16 md:mt-20 text-black text-lg sm:text-xl md:text-2xl px-6 py-4 mb-4 rounded-full transition duration-200 animate-fade-up"
                                style={{
                                    fontFamily: 'Montserrat, sans-serif',
                                    backgroundColor: '#d8bf82',
                                    color: 'black',
                                    borderColor: 'white',
                                }}
                                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#a78c5d')}
                                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#d8bf82')}
                            >
                                ABOUT INJSUR.AI
                            </button>
                        </Link>
                    </div>
                </section>

                {/* Section 2 */}
                <section className="h-auto py-24 sm:py-20 md:py-24 relative flex flex-col bg-cover text-white" style={{ fontFamily: 'Montserrat, sans-serif', backgroundImage: `url(${img9})`, backgroundPosition: 'center 20%' }}>
                    <p className="text-4xl sm:text-5xl md:text-7xl font-bold mb-10 sm:mb-16 md:mb-10 text-left ml-8">
                        TOP 5 PLAYERS
                    </p>

                    <p className="text-lg sm:text-xl md:text-2xl text-left mx-8" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                        Injsur.AI Player Cards provide easy access to crucial insights and probabilities of health and performance for NFL players, helping you make the best decisions possible empowered by real data science. Flip the cards for a sample of Injsur.AI player statistics.
                    </p>

                    {/* <img src={img6} alt="Insightful Image" className="w-full h-auto" /> */}
                    <PlayerCardsSection />
                </section>




                {/* Section 3 */}
                <section className="h-auto relative flex items-center justify-center text-green-900">
                    <div className="flex w-full">
                        {/* Left side: Football image taking 33% of the section, hidden on mobile */}
                        <div className="hidden lg:block w-2/5">
                            <img src={imgfootball} alt="Football" className="h-full w-full object-cover" />
                        </div>
                        {/* Right side: Text overlaid on img8 taking 67% of the section */}
                        <div className="w-full lg:w-3/5 relative flex flex-col justify-center items-center text-left"
                            style={{
                                backgroundImage: `url(${img8})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center right', // Shift img8 to the right
                            }}>
                            <div className="px-10 py-12 sm:px-20 md:px-40 lg:px-52" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                                <p className="text-2xl sm:text-3xl md:text-4xl lg:text-3xl mb-6 uppercase">
                                    <span className='font-bold'>INJSUR.AI</span> STANDS AT THE FOREFRONT OF SPORTS TECHNOLOGY, SPECIALIZING IN PREDICTIVE HEALTH ANALYTICS TRAINED SPECIFICALLY FOR NFL PLAYERS THROUGH ITS ADVANCED AI PLATFORM.
                                </p>
                                <p className="text-lg sm:text-xl md:text-2xl lg:text-2xl mb-4" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                                    With our proprietary and patent pending Machine Learning models, <span className='font-bold'>Injsur.AI</span> provides continuous data updates and invaluable insights into injury probabilities which affect game results and your winnings.
                                </p>

                                <p className="text-lg sm:text-xl md:text-2xl lg:text-2xl mb-4 font-bold" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                                    $24.99 Season Pass
                                    <br></br>
                                    Subscribe today and receive a 20% discount on 2025 NFL season                                </p>
                                {/* <p className="text-lg sm:text-xl md:text-2xl lg:text-2xl mb-4 font-bold" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                                    In the near future, we will offer innovative customizable insurance solutions against player injuries for hedging fantasy sports and other betting scenarios.
                                </p> */}
                                <Link to="/login">
                                    <button
                                        className="font-bold mt-4 text-black text-lg sm:text-xl md:text-2xl px-6 py-4 mb-4 rounded-full transition duration-200"
                                        style={{
                                            fontFamily: 'Montserrat, sans-serif',
                                            backgroundColor: '#d8bf82',
                                            color: 'black',
                                            borderColor: 'white',
                                        }}
                                        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#a78c5d')}
                                        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#d8bf82')}
                                    >
                                        SUBSCRIBE TO INJSUR.AI
                                    </button>

                                </Link>
                            </div>
                        </div>
                    </div>
                </section>





                {/* Section 3 */}
                <section className="h-auto py-16 sm:py-20 md:py-24 relative flex flex-col items-left justify-left text-left bg-cover px-10 sm:px-10 md:px-20 lg:px-60 text-white" style={{ backgroundImage: `url(${img9})`, backgroundPosition: 'center 20%' }}>
                    <div className="w-full lg:w-1/2">
                        <p className="text-4xl sm:text-5xl md:text-6xl font-bold mb-10 sm:mb-16 md:mb-20 uppercase" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                            INJSUR.AI DELIVERS COMPREHENSIVE DATA SETS:
                        </p>
                        <ul className="text-lg sm:text-xl md:text-2xl lg:text-3xl mb-6 list-disc list-outside space-y-4 ml-4" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                            <li>Player performance metrics: data such as yards, tackles, completions, and more</li>
                            <li>Injury history: detailed records of past injuries, including type and severity</li>
                            <li>Game conditions: information on weather, field type, and other environmental factors</li>
                        </ul>
                        <Link to="/learn-more">
                            <button
                                className="font-bold mt-4 text-black text-lg sm:text-xl md:text-2xl px-6 py-4 mb-4 ml-6 rounded-full transition duration-200"
                                style={{
                                    fontFamily: 'Montserrat, sans-serif',
                                    backgroundColor: '#d8bf82',
                                    color: 'black',
                                    borderColor: 'white',
                                }}
                                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#a78c5d')}
                                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#d8bf82')}
                            >
                                LEARN MORE
                            </button>

                        </Link>
                    </div>
                </section>








                {/* Combined Section 4 */}
                <section
                    className="h-full py-16 sm:py-20 md:py-24 relative flex flex-col items-left justify-left bg-no-repeat px-10 sm:px-10 md:px-20 lg:px-40 text-green-950"
                    style={{
                        backgroundImage: `url(${img10})`,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center'
                    }}
                >
                    {/* Testimonials */}
                    <p className="text-4xl sm:text-5xl md:text-5xl font-bold mb-10 sm:mb-16 md:mb-20 text-left" style={{ fontFamily: 'Montserrat, sans-serif', color: '#d8bf82' }}>
                        TESTIMONIALS
                    </p>
                    <ul className="flex flex-col sm:flex-row justify-center items-start text-lg sm:text-xl md:text-2xl lg:text-3xl mb-6 text-left space-y-8 sm:space-y-0 sm:space-x-24 list-none">
                        <li className="flex items-center space-x-10 sm:space-x-10">
                            <div className="text-left">
                                <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl leading-snug" style={{ fontFamily: 'Montserrat, sans-serif' }}>Injsur.AI is the most robust data source for Fantasy Football anyone has ever seen.</p>
                                <p className="text-[1.5rem] mt-4 font-bold">- BRET P.</p>
                            </div>
                        </li>
                        <li className="flex items-center space-x-4 sm:space-x-6">
                            <div className="text-left">
                                <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl leading-snug" style={{ fontFamily: 'Montserrat, sans-serif' }}>Injsur.AI is going to make all the difference in how I approach this season.</p>
                                <p className="text-[1.5rem] mt-4 font-bold">- DR. D ALLEN</p>
                            </div>
                        </li>
                        <li className="flex items-center space-x-4 sm:space-x-6">
                            <div className="text-left">
                                <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl leading-snug" style={{ fontFamily: 'Montserrat, sans-serif' }}>Injsur is a game changer for my Fantasy Football League and weekly parlays.</p>
                                <p className="text-[1.5rem] mt-4 font-bold">- JOHN D.</p>
                            </div>
                        </li>
                    </ul>



                    {/* Horizontal line */}
                    {/* <hr className="w-full border-t-2 border-[#d8bf82] my-12 sm:my-16 md:my-20" /> */}

                    {/* Expert Analysis */}
                    {/* <p className="text-4xl sm:text-5xl md:text-5xl font-bold mb-10 sm:mb-16 md:mb-20 text-left" style={{ fontFamily: 'Montserrat, sans-serif', color: '#d8bf82' }}>
                        EXPERT ANALYSIS
                    </p>
                    <ul className="flex flex-col sm:flex-row justify-center items-start text-lg sm:text-xl md:text-2xl lg:text-4xl mb-6 text-left space-y-8 sm:space-y-0 sm:space-x-8 list-none">
                        <li className="flex flex-col items-start">
                            <p className="text-[1.5rem] mb-2 font-bold">July 24th, 2024</p>
                            <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl leading-snug mb-4" style={{ fontFamily: 'Montserrat, sans-serif' }}>Top 15 Fantasy TEs for 2024: Scoring Big and Staying on the Field</p>
                            <a href="/articles" className="text-[1.5rem] mt-auto text-green-950 underline font-bold hover:underline">
                                READ MORE
                            </a>
                        </li>
                        <li className="flex flex-col items-start">
                            <p className="text-[1.5rem] mb-2 font-bold">July 24th, 2024</p>
                            <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl leading-snug mb-4" style={{ fontFamily: 'Montserrat, sans-serif' }}>From Rookie to Champ: 5 Reasons Fantasy Football is Your New Obsession</p>
                            <a href="/articles" className="text-[1.5rem] mt-auto text-green-950 font-bold underline hover:underline">
                                READ MORE
                            </a>
                        </li>
                        <li className="flex flex-col items-start">
                            <p className="text-[1.5rem] mb-2 font-bold">July 24th, 2024</p>
                            <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl leading-snug mb-4" style={{ fontFamily: 'Montserrat, sans-serif' }}>Top 10 Rookie Sleepers for 2024: Finding the Healthy Gems in the Rough</p>
                            <a href="/articles" className="text-[1.5rem] mt-auto text-green-950 font-bold underline hover:underline">
                                READ MORE
                            </a>
                        </li>
                        <li className="flex flex-col items-start">
                            <p className="text-[1.5rem] mb-2 font-bold">July 24th, 2024</p>
                            <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl leading-snug mb-4" style={{ fontFamily: 'Montserrat, sans-serif' }}>The New Era of Fantasy Football: Balancing Potential and Risk</p>
                            <a href="/articles" className="text-[1.5rem] mt-auto text-green-950 font-bold underline hover:underline">
                                READ MORE
                            </a>
                        </li>
                    </ul> */}
                </section>

                <section className="h-auto py-16 sm:py-16 md:py-16  flex  items-left justify-left text-left bg-cover px-10 text-green-950" style={{ backgroundImage: `url(${img4})`, backgroundPosition: 'center 20%' }}>
                    <div className="">
                        <p
                            className="text-4xl sm:text-5xl md:text-6xl font-bold mb-10 sm:mb-16 md:mb-20 uppercase whitespace-nowrap"
                            style={{ fontFamily: 'Montserrat, sans-serif' }}
                        >
                            COMING SOON
                        </p>


                        <p className="text-lg sm:text-xl md:text-3xl text-left " style={{ fontFamily: 'Montserrat, sans-serif' }}>
                            In the near future, we will introduce innovative new features to improve your experience, such as customizable data sets, expert analysis and scoring projections. Injsur.AI will ultimately provide insurance solutions to help you hedge against player injuries and losses for fantasy sports and other betting scenarios.                        </p>

                    </div>
                </section>



                {/* Section 8 */}
                <section className="h-auto py-24 sm:py-20 md:py-24 relative flex flex-col items-left justify-left bg-green-950 px-10 sm:px-10 md:px-10 lg:px-10 xl:px-10 text-black">
                    <p className="text-4xl sm:text-3xl  md:text-5xl font-bold mb-10 sm:mb-16 md:mb-10 text-left" style={{ fontFamily: 'Montserrat, sans-serif', color: '#d8bf82' }}>
                        CONTACT US
                    </p>
                    <div className="flex flex-wrap justify-left items-left  gap-8 md:gap-20 lg:gap-20  w-full text-white text-3xl">
                        <ul className="list-none">
                            <li className="mb-4"><a href="mailto:support@injsur.ai" className="underline">support@injsur.ai</a></li>
                            <li className="mb-4"><a href="mailto:partners@injsur.ai" className="underline">partners@injsur.ai</a></li>
                            <li className="mb-4"><a href="mailto:advertisers@injsur.ai" className="underline">advertisers@injsur.ai</a></li>
                        </ul>

                    </div>

                </section>


                {/* Section 8
                <section className="h-auto py-24 sm:py-20 md:py-24 relative flex flex-col items-left justify-left bg-green-950 px-4 sm:px-10 md:px-10 lg:px-10 xl:px-10 text-black">
                    <p className="text-4xl sm:text-3xl md:text-3xl font-bold mb-10 sm:mb-16 md:mb-20 text-left" style={{ fontFamily: 'Montserrat, sans-serif', color: '#d8bf82' }}>
                        INJSUR.AI PARTNERSHIPS
                    </p>
                    <div className="flex flex-wrap justify-center items-center gap-8 md:gap-20 lg:gap-20 w-full">
                        <img src={imgmgm} alt="Partner MGM" className="h-auto max-w-[200px] sm:max-w-[150px] md:max-w-[180px] lg:max-w-[220px] object-contain" />
                        <img src={imgbet365} alt="Partner Bet365" className="h-auto max-w-[200px] sm:max-w-[150px] md:max-w-[180px] lg:max-w-[220px] object-contain" />
                        <img src={imgfanatics} alt="Partner Fanatics" className="h-auto max-w-[200px] sm:max-w-[150px] md:max-w-[180px] lg:max-w-[220px] object-contain" />
                        <img src={imgcaesars} alt="Partner Caesars" className="h-auto max-w-[200px] sm:max-w-[150px] md:max-w-[180px] lg:max-w-[220px] object-contain" />
                    </div>
                </section> */}



            </div>
            <CookieBanner />

            <Footer />
        </div>
    );
};

export default Home;
