import React, { useContext, useCallback, useState, useEffect, useRef } from 'react';
import * as tf from '@tensorflow/tfjs'; // Import TensorFlow.js
import { FrailtyModelContext } from './FrailtyModelContext';
import Game from './Game'; // Use Game component to display players
import UserHeader from './UserHeader';

const FrailtyGames = ({ game, onBack }) => {
    const { model, loading, error } = useContext(FrailtyModelContext); // Access the model from context
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal open/close
    const [selectedTeam, setSelectedTeam] = useState('home'); // Track the selected team
    const modalRef = useRef(null); // Ref to track the modal element

    const predictFrailty = useCallback(async (playerDetails, playerStats) => {
        if (!model) {
            console.error("Model is not loaded yet. Cannot predict frailty.");
            return null;
        }

        try {
            const data = tf.tensor2d([Object.values(playerStats)], [1, Object.keys(playerStats).length]);
            const predictions = model.predict(data);
            const frailtyScoreTensor = await predictions.data();
            const frailtyScore = frailtyScoreTensor[0] * 10;
            return frailtyScore.toFixed(1);
        } catch (error) {
            console.error("Error during frailty prediction:", error);
            return null;
        }
    }, [model]);

    const calculateAdjustedFrailtyScore = useCallback((frailtyScore, position) => {
        let adjustedFrailtyScore = parseFloat(frailtyScore);
        const minOriginal = 0.0;
        const maxOriginal = 6.0;
        const minTarget = 1.0;
        const maxTarget = 10.0;

        adjustedFrailtyScore = Math.min(Math.max(adjustedFrailtyScore, minOriginal), maxOriginal);
        const mappedFrailtyScore = minTarget + ((adjustedFrailtyScore - minOriginal) * (maxTarget - minTarget) / (maxOriginal - minOriginal));

        return mappedFrailtyScore;
    }, []);

    // Handle click outside the modal to close it
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isModalOpen && modalRef.current && !modalRef.current.contains(event.target)) {
                setIsModalOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isModalOpen]);

    // Ensure game object exists before rendering
    if (!game || loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    // Use correct fields from the `game` object based on your API structure
    const homeTeamName = game.home_team;
    const awayTeamName = game.away_team;
    const homeTeamId = game.home_team_id;
    const awayTeamId = game.away_team_id;
    const homeTeamLogo = game.home_team_logo;
    const awayTeamLogo = game.away_team_logo;

    return (
        <div>
            <div className="flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-4 mb-4 p-10">
                <button
                    onClick={onBack}
                    className="bg-zinc-900 hover:bg-zinc-950 text-white font-bold py-3 px-8 rounded"
                >
                    Back
                </button>

                <h2 className="text-xl font-bold">
                    {`${homeTeamName} vs. ${awayTeamName}`} Player Frailty Cards
                </h2>

                <input
                    type="text"
                    placeholder="Search Players..."
                    className="w-full md:w-1/3 p-2 border rounded-md"
                    onChange={(e) => setSearchTerm(e.target.value)}
                />

                <button
                    onClick={() => setIsModalOpen(true)} // Open the modal on click
                    className="bg-zinc-900 hover:bg-zinc-950 font-bold hover:text-[#d8bf82] text-white p-2 rounded-md"
                >
                    How Frailty Scores Work
                </button>
            </div>

            {/* Team Tabs */}
            <div className="flex justify-center mb-5">
                <button
                    onClick={() => setSelectedTeam('home')}
                    className={`mr-4 px-4 py-2 rounded ${
                        selectedTeam === 'home' ? 'bg-zinc-900 text-[#d8bf82] font-bold' : 'bg-gray-300 text-black hover:bg-gray-400'
                    }`}
                >
                    {homeTeamName}
                </button>
                <button
                    onClick={() => setSelectedTeam('away')}
                    className={`px-4 py-2 rounded ${
                        selectedTeam === 'away' ? 'bg-zinc-900 text-[#d8bf82] font-bold' : 'bg-gray-300 text-black hover:bg-gray-400'
                    }`}
                >
                    {awayTeamName}
                </button>
            </div>

            {/* Game component to show players of the selected team */}
            <Game
                teamId={selectedTeam === 'home' ? homeTeamId : awayTeamId}
                teamName={selectedTeam === 'home' ? homeTeamName : awayTeamName}
                predictFrailty={predictFrailty}
                calculateAdjustedFrailtyScore={calculateAdjustedFrailtyScore}
                searchTerm={searchTerm}
            />

            {/* Modal */}
            {isModalOpen && (
                <div
                    className="fixed inset-0 flex items-center justify-center z-50"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                >
                    <div ref={modalRef} className="relative bg-white rounded-lg p-8 max-w-lg w-full mx-4 h-4/5 overflow-y-auto">
                        <h2 className="text-2xl font-bold mb-4">How Frailty Scores Work</h2>
                        <p className="mb-4">
                            Injsur.ai is a cutting-edge application that uses advanced machine learning to assess the frailty of NFL players. Our proprietary model, trained on extensive player data dating back to 2005, provides our users with crucial insights into the statistical probability of player health and performance.
                        </p>
                        <button
                            onClick={() => setIsModalOpen(false)} // Close the modal on click
                            className="bg-red-500 text-white p-2 rounded-md mt-4"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FrailtyGames;
