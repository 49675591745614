import React, { useState, useEffect } from 'react';
import {
    fetchQuarterbackDetails,
    fetchPlayerStatistics,
} from './utilityFunctions';
import PlayerCard from './PlayerCard';
import offensivePlayers from './combined_players.json'; // Import playersOff.json

const Quarterbacks = ({ predictFrailty, calculateAdjustedFrailtyScore, searchTerm }) => {
    const [players, setPlayers] = useState([]);
    const [fetchedPlayers, setFetchedPlayers] = useState([]); // Store fetched players separately
    const [outputMessage, setOutputMessage] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchQuarterbacks = async () => {
            setOutputMessage('Fetching quarterbacks...');
            setLoading(true); // Set loading to true before fetching data

            try {
                // Filter playersOff.json to include only QBs
                const qbPlayers = offensivePlayers.QB || [];
                console.log("All QB players:", qbPlayers);

                if (qbPlayers.length === 0) {
                    setOutputMessage('No quarterbacks found.');
                    setLoading(false);
                    return;
                }

                // Fetch player details and stats for QBs
                const playerDetailsWithStats = await Promise.all(
                    qbPlayers.map(async (player) => {
                        
                        const playerDetails = await fetchQuarterbackDetails({ player });

                        if (playerDetails) {
                            const statsResult = await fetchPlayerStatistics(player);

                            // Calculate frailty score regardless of injury status
                            const { displayStats2023, displayStats2024 } = statsResult || {};
                            let frailtyScore = "No Stats";

                            if (statsResult?.normalizedStats) {
                                frailtyScore = await predictFrailty(playerDetails, statsResult.normalizedStats);
                                frailtyScore = !isNaN(parseFloat(frailtyScore)) ? parseFloat(frailtyScore) : "No Stats";
                            }

                            const adjustedFrailtyScore = frailtyScore === "No Stats" ? "No Stats" : calculateAdjustedFrailtyScore(frailtyScore, playerDetails);

                            // Return player details with stats, frailty score, and injury status
                            return {
                                ...player,
                                details: playerDetails,
                                stats2023: displayStats2023,  // 2023 stats
                                stats2024: displayStats2024,  // 2024 stats
                                frailtyScore: adjustedFrailtyScore,
                                injury: statsResult?.injury, // Pass along injury information
                                age: playerDetails.age,
                                height: playerDetails.height,
                                weight: playerDetails.weight,
                                experience: playerDetails.experience,
                                preExistingInjuries: playerDetails.preExistingInjuries,
                                missedGamePercentage: playerDetails.missedGamePercentage,
                                missedGamePercentageThree: playerDetails.missedGamePercentageThree
                                

                            };
                        }

                        // Return null if playerDetails or stats are missing
                        return null;
                    })
                );

                // Filter out players with no stats (frailty score of "No Stats")
                const filteredPlayers = playerDetailsWithStats
                    .filter(player => player !== null && player.frailtyScore !== "No Stats");

                const uniquePlayers = Array.from(
                    new Map(filteredPlayers.map(player => [player.details.id, player])).values()
                );

                // Sort players
                const sortedPlayers = uniquePlayers.sort((a, b) => {
                    // First handle non-questionable injured players to move them to the bottom
                    if (a.injury?.status !== 'Questionable' && a.injury) return 1;
                    if (b.injury?.status !== 'Questionable' && b.injury) return -1;

                    // Sort players (including questionable) by frailty score in descending order
                    if (typeof a.frailtyScore === 'number' && typeof b.frailtyScore === 'number') {
                        return b.frailtyScore - a.frailtyScore;
                    } else if (typeof a.frailtyScore === 'number') {
                        return -1;
                    } else if (typeof b.frailtyScore === 'number') {
                        return 1;
                    }

                    return 0;
                });

                if (sortedPlayers.length > 0) {
                    setFetchedPlayers(sortedPlayers);
                    setPlayers(sortedPlayers);
                    setOutputMessage('');
                } else {
                    setOutputMessage('No quarterbacks found with valid data.');
                }
                setLoading(false); // Data fetched, stop loading
            } catch (error) {
                setOutputMessage(`Error fetching quarterbacks: ${error.message}`);
                setLoading(false); // Stop loading on error
            }
        };

        fetchQuarterbacks();
    }, [predictFrailty, calculateAdjustedFrailtyScore]); // Keep necessary dependencies

    // Filter players based on searchTerm without triggering API calls
    useEffect(() => {
        const filteredPlayers = fetchedPlayers.filter((player) =>
            player.details?.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setPlayers(filteredPlayers); // Update players state with filtered results
    }, [searchTerm, fetchedPlayers]); // Only run when searchTerm or fetchedPlayers change


    return (
        <div>
            {loading && (
                <div className="fixed top-1/3 left-0 w-full h-full flex flex-col items-center justify-start z-50 bg-white bg-opacity-70">
                    <div className="w-16 h-16 border-t-4 border-b-4 border-green-500 rounded-full animate-spin mt-8"></div>
                    <p className="mt-4 text-lg text-black">Loading Players...</p>
                </div>
            )}
            {!loading && outputMessage && <p>{outputMessage}</p>}

            {!loading && players.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 justify-center place-items-center">
                    {players.map((player, index) => (
                        <PlayerCard
                            key={index}
                            playerId={player.details?.id}
                            playerImage={player.details?.image}
                            teamName={player.details?.team}
                            frailtyScore={typeof player.frailtyScore === 'number' && !isNaN(player.frailtyScore) ? player.frailtyScore.toFixed(2) : player.frailtyScore}
                            playerName={player.details?.name}
                            position={player.details?.position}
                            playerStats2023={player.stats2023} // 2023 stats
                            playerStats2024={player.stats2024} // 2024 stats
                            injury={player.injury}
                            age={player.age}
                            height={player.details?.height}
                            weight={player.details?.weight}
                            experience={player.details?.experience}
                            preExistingInjuries={player.details?.preExistingInjuries}
                            missedGamePercentage={player.details?.missedGamePercentage} // Pass missed game percentage
                            missedGamePercentageThree={player.details?.missedGamePercentageThree}
                        />
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default Quarterbacks;
